/* You can add global styles to this file, and also import other style files */
html{
    scrollbar-width: none; /* Firefox 64 */
}
body {
    /*scroll-behavior: smooth;*/
    overflow: hidden;
    margin: 0px;
    padding: 0px;
    max-width: 100vw;
    max-height: 100vh;
}
body *{
    max-width: 100vw;
    max-height: 100vh;    
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */    
}
::-webkit-scrollbar { display: none; }


:root{
    --transition-short: .4s;
}
.ng-trigger-routeAnimations{
    min-height: 100vh;
}